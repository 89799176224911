/* You can add global styles to this file, and also import other style files */
@use './assets/theme/variables';
@use './assets/theme/base';
@import './assets/styles/global';
@import './assets/styles/dashboard_schedule';
@import "~@ng-select/ng-select/themes/default.theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



 
/* Define global styles */
body {
  font-family: "Lato", sans-serif; /* Set Lato as the default font */
}
 
h1 {
  font-family: "Roboto", sans-serif; /* Set Roboto for header tags */
}

h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif; /* Set Lato for sub header tags */
}